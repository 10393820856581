<script setup lang="ts">
import { onBeforeUnmount, onMounted, Ref, ref } from 'vue'
import SvgIcon from '../SvgIcon.vue'
import { AsideSection } from '@/interfaces/AsideSection';
import BackButton from '@/components/Buttons/BackButton.vue'

const props = defineProps<{
    page: never,
}>();

const OFFSET_TOP = 160;
const isSidebarVisible = ref(false);
const currentSection = ref('header');

const sections: Ref<Array<AsideSection>> = ref([
    { id: 'header', label: 'Le métier', icon: 'briefcase' },
]);

const sectionMappings = [
    { prop: 'show_qualities_aptitudes', id: 'qualities-aptitudes', label: 'Qualités et Aptitudes', icon: 'chartbar' },
    { prop: 'show_daily', id: 'daily', label: 'Quotidien', icon: 'sun' },
    { prop: 'show_access_path', id: 'access-path', label: 'Voie d’accès', icon: 'student' },
    { prop: 'show_evolution_prospects', id: 'evolution', label: 'Perspectives d’évolution', icon: 'arrowfatlineup' },
    { prop: 'show_employer', id: 'employer', label: 'Employeurs', icon: 'briefcase' },
    { prop: 'show_statistics', id: 'statistics', label: 'Statistiques', icon: 'chartlineup' },
    { prop: 'show_interest_website', id: 'interest-website', label: 'Sites d’intérêt', icon: 'globe' },
    { prop: 'show_documentation', id: 'documentations', label: 'Documentation', icon: 'file' },
    { prop: 'show_related_job', id: 'related-job', label: 'Métiers en lien', icon: 'link' },
];
sectionMappings.forEach(mapping => {
    if (props.page[mapping.prop]) {
        sections.value.push({ id: mapping.id, label: mapping.label, icon: mapping.icon });
    }
});
sections.value.push({ id: 'actus', label: 'Actus et événements', icon: 'newspaper' })

const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - OFFSET_TOP,
            behavior: 'smooth',
        });
        isSidebarVisible.value = false;
    }
};

const handleScroll = () => {
    const scrollPosition = window.scrollY;

    for (const section of sections.value) {
        const element = document.getElementById(section.id);
        if (element) {
            const offset = element.offsetTop - OFFSET_TOP;
            if (scrollPosition >= offset && scrollPosition < offset + element.clientHeight) {
                currentSection.value = section.id;
                break;
            }
        }
    }
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <div class="flex justify-between max-w-[100rem] mx-auto pt-8 md:pt-14">
        <aside class="self-start hidden w-[280px] md:block pl-4 w-70 sticky top-40 col-span-1" aria-label="Sidebar">
            <div class="h-full p-4 overflow-y-auto bg-white rounded-[16px] border border-gray-light">
                <div class="hidden lg:block mb-4 absolute -top-10 pl-6">
                    <BackButton title="Tous les métiers" back-url="/catalogue" />
                </div>

                <ul class="space-y-2 font-medium">
                    <li v-for="section in sections" :key="section.id">
                        <button
                            type="button" :class="{ 'active': currentSection === section.id }"
                            class="sidebar-link"
                            @click="scrollToSection(section.id)"
                        >
                            <SvgIcon :name="section.icon" class="w-6 h-6 shrink-0" />
                            <span class="ms-3" v-html="section.label" />
                        </button>
                    </li>
                </ul>
            </div>
        </aside>

        <div class="w-full px-6 overflow-hidden">
            <div class="lg:hidden block mb-4">
                <BackButton title="Tous les métiers" back-url="/catalogue" />
            </div>
            <div class="space-y-20">
                <slot />
            </div>
        </div>
    </div>
</template>

<style scoped>
.sidebar-link{
    @apply flex text-gray-medium items-center text-left p-2 rounded-lg hover:bg-gray-50 w-full pl-4 transition duration-150;
}
.active{
    @apply text-primary;
}
</style>
