<script setup lang="ts">

</script>

<template>
    <div class="jobcard animate-pulse">
        <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded dark:bg-gray-700">
            <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
        </div>
        <div class="p-4 bg-white rounded-tl-3xl rounded-tr-3xl -mt-4 z-20 relative">
            <div class="h-[60px] pt-3 mb-2">
                <div class="h-2.5 bg-gray-200 rounded-full w-48 mb-4" />
                <div class="h-2.5 bg-gray-200 rounded-full w-[70%] mb-4" />
            </div>

            <hr class="my-3 border-gray-light">

            <div class="flex items-center gap-4 justify-start pt-3.5">
                <div
                    v-for="index in 3" :key="index"
                    class="h-2 bg-gray-200 block w-[35px] rounded-full  mb-2.5"
                />
            </div>
            <div class="h-2 bg-gray-200 block w-[80%] rounded-full mt-7 mb-2.5" />
        </div>
    </div>
</template>

<style scoped>
.jobcard{
    @apply bg-white block w-full transition duration-300 relative select-none overflow-hidden h-fit;
    border-radius: 24px;
    border: 1px solid #EDEDED;
}

.jobcard img{
    mask-image: linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(0,0,0, 1) 100%)
}
</style>
