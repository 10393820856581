import { Dates } from '@/interfaces/Dates';
import { FilterParameter } from '@/interfaces/FilterParameter';

export function useFilters() {
    function arrayToQueryString(paramName: string, values: string[]) {
        if (!Array.isArray(values)) return '';

        return values.map(value => `&${paramName}[]=${encodeURIComponent(value)}`).join('&');
    }

    const rangeDateToQueryString = (dates: Dates) => {
        if(dates.startDate !== '' && dates.endDate !== '') {
            return `&start_date=${dates.startDate}&end_date=${dates.endDate}`;
        }else{
            return '';
        }
    }

    const handleSubmit = (data: FilterParameter) => {
        const formattedQ = encodeURIComponent(data.search ?? '');
        const formattedDomains = arrayToQueryString('domains', data.domains ?? []);
        const formattedCategories = arrayToQueryString('categories', data.categories ?? []);
        const formattedDate = rangeDateToQueryString({ startDate: data.start_date ?? '', endDate: data.end_date ?? '' })
        location.replace(`?search=${formattedQ}${formattedDomains}${formattedCategories}${formattedDate}`)
    }

    return { handleSubmit }
}
