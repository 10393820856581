<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { Document } from '@/interfaces/Document';
import SvgIcon from '@/components/SvgIcon.vue'
import { Collapse } from 'vue-collapsed'
import { ref } from 'vue'
import { PhLink, PhMinus, PhPlus } from '@phosphor-icons/vue'

const props = defineProps<{
    links: Array<Document>,
}>();

const isExpanded = ref(false)
</script>

<template>
    <div>
        <h3 class="flex text-xl font-semibold uppercase items-center gap-2 mb-4">
            Liens
            <Tippy
                theme="light"
                content="Explorez les liens pertinents pour accéder au règlement détaillé des formations post-diplômes
                spécialisées en soins"
            >
                <SvgIcon name="info" class="text-gray-400 w-6" />
            </Tippy>
        </h3>

        <div v-for="link in links.slice(0, 5)" :key="link.id" class="mb-3">
            <div class="font-medium underline underline-offset-2 flex items-center gap-2">
                <PhLink size="22" class="fill-current text-gray-400" />
                <a :href="link.link" target="_blank" class="hover:text-primary-light transition duration-150">{{ link.title }}</a>
            </div>
        </div>

        <Collapse :when="isExpanded">
            <div v-for="link in links.slice(5, 50)" :key="link.id" class="mb-3">
                <div class="font-medium underline underline-offset-2 flex items-center gap-2">
                    <PhLink size="22" class="fill-current text-gray-400" />
                    <a :href="link.link" target="_blank" class="hover:text-primary-light transition duration-150">
                        {{ link.title }}
                    </a>
                </div>
            </div>
        </Collapse>

        <button
            v-if="links.length > 5"
            class="flex items-center gap-2 hover:text-gray-700 text-sm pt-2"
            @click="isExpanded = !isExpanded"
        >
            <span v-if="isExpanded">Afficher moins de liens</span>
            <span v-else>Afficher plus de liens</span>
            <PhMinus v-if="isExpanded" size="16" class="fill-current" />
            <PhPlus v-else size="16" class="fill-current" />
        </button>
    </div>
</template>
