<script setup lang="ts">
import { Media } from '@/interfaces/Media'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { convertYouTubeUrlToNoCookieEmbed } from '@/utils'
import Swiper from 'swiper'
import { Navigation, Autoplay } from 'swiper/modules'
import SvgIcon from '@/components/SvgIcon.vue'

const props = defineProps<{
    medias: Array<Media>
}>();

const modalOpen = ref(false)
const currentIndex = ref(0)
const currentImg = computed(() => props.medias[currentIndex.value])

const openModal = (index: number) => {
    currentIndex.value = index;
    modalOpen.value = true;
}

const nextImg = () => {
    if (currentIndex.value >= props.medias.length - 1) {
        currentIndex.value = 0;
    } else {
        currentIndex.value++;
    }
}

const backImg = () => {
    if (currentIndex.value <= 0) {
        currentIndex.value = props.medias.length - 1;
    } else {
        currentIndex.value--;
    }
}

const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight") {
        nextImg();
    } else if (e.key === "ArrowLeft") {
        backImg();
    }
    if (e.key === 'Escape'){
        modalOpen.value = false
    }
}

onMounted(() => {
    window.addEventListener('keydown', handleKeydown);

    new Swiper('.gallery-swiper', {
        modules: [Navigation, Autoplay],
        autoplay: {
            delay: 5000,
        },
        loop: true,
        spaceBetween: 20,
        breakpoints: {
            320: { slidesPerView: 1, },
            480: { slidesPerView: 2, },
            900: { slidesPerView: 3, },
            1200: { slidesPerView: 5, }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
})

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeydown);
})
</script>

<template>
    <div class="gallery-swiper images-container w-full -mb-[40px] relative">
        <div class="swiper-wrapper">
            <!-- Slides -->
            <div
                v-for="(media, index) in medias" :key="index"
                class="swiper-slide rounded-[10px] overflow-hidden"
            >
                <div class="flex">
                    <button v-if="media.image_video" class="h-full w-full" @click="openModal(index)">
                        <img
                            v-if="media.image_video.is_image" :src="media.image_video.permalink"
                            class="rounded-[8px] hover:cursor-pointer object-cover w-full lg:h-full aspect-[5/3]" alt=""
                        >

                        <video
                            v-else-if="media.image_video.is_video"
                            controls
                            class="object-cover w-full rounded-[8px] lg:h-full aspect-[5/3]"
                        >
                            <source :src="media.image_video.permalink" :type="media.image_video.mime_type">
                            Your browser does not support the video tag.
                        </video>
                    </button>

                    <iframe
                        v-else-if="media.video_url" class="rounded-[8px] aspect-[5/3]"
                        width="100%" height="100%" title="YouTube video player" allowfullscreen
                        :src="convertYouTubeUrlToNoCookieEmbed(media.video_url)"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                        web-share"
                    />
                </div>
            </div>
        </div>

        <button class="swiper-button-prev swiper-btn left-4" :class="medias.length < 6 ? 'md:!hidden' : ''">
            <SvgIcon name="arrowup" class="w-5 md:w-6 -rotate-90" />
        </button>

        <button class="swiper-button-next swiper-btn right-4" :class="medias.length < 6 ? 'md:!hidden' : ''">
            <SvgIcon name="arrowup" class="w-5 md:w-6 rotate-90" />
        </button>
    </div>

    <Transition>
        <div v-if="modalOpen" class="fixed top-0 left-0 w-full h-full z-50">
            <div class="w-full h-full bg-[rgb(0,0,0,0.8)]" @click="modalOpen = false" />

            <button
                class="h-12 w-12 bg-white absolute rounded-full top-1/2 left-2 md:left-8 flex justify-center
                items-center -translate-y-1/2"
                @click="backImg"
            >
                <SvgIcon name="arrowup" class="w-6 -rotate-90" />
            </button>
            <button
                class="h-12 w-12 bg-white absolute rounded-full top-1/2 right-2 md:right-8 flex justify-center
                items-center -translate-y-1/2"
                @click="nextImg"
            >
                <SvgIcon name="arrowup" class="w-6 rotate-90" />
            </button>

            <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div v-if="currentImg.image_video">
                    <img
                        v-if="currentImg.image_video.is_image"
                        width="auto" height="auto"
                        :src="currentImg.image_video.permalink" class="rounded-[8px]" alt=""
                    >

                    <video
                        v-else-if="currentImg.image_video.is_video"
                        controls
                        class="object-cover h-full w-full rounded-[8px]"
                    >
                        <source :src="currentImg.image_video.permalink" :type="currentImg.image_video.mime_type">
                        Your browser does not support the video tag.
                    </video>

                    <div class="text-white text-sm mt-2 opacity-75">
                        {{ currentImg.copyright }}
                    </div>
                </div>

                <iframe
                    v-else-if="currentImg.video_url" class="rounded-[8px] aspect-video lg:w-[500px]"
                    :src="convertYouTubeUrlToNoCookieEmbed(currentImg.video_url)"
                    width="100%" height="100%" title="YouTube video player" allowfullscreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;web-share"
                />
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.images-container {
    @apply bg-white p-2.5 overflow-hidden rounded-[16px] border border-gray-light flex items-center;
    background: url('/assets/dev/images-bg.png') center;
    background-size: cover;
}

.swiper-btn{
    @apply absolute h-10 w-10 lg:h-11 lg:w-11 bg-white border border-gray-light top-1/2 transform
    -translate-y-1/2 rounded-full flex justify-center items-center z-20 hover:cursor-pointer;
}
</style>
