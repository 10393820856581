<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

const props = defineProps<{
    name: string,
}>();

const Icon = defineAsyncComponent(() => import(`../../svg/icons/regular/${props.name}.svg?component`))
</script>

<template>
    <Icon />
</template>
