<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'
import { Domain } from '@/interfaces/Domain';
import { Category } from '@/interfaces/Category';
import { useWindowSize } from "@vueuse/core";
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import { generateSessionCode } from '@/utils'

const props = withDefaults(defineProps<{
    id: string;
    link: string;
    image: string;
    title: string;
    domains?: Array<Domain>;
    categories?: Array<Category>;
    cardIsClickable?: boolean;
    hideFavButton?: boolean;
    smallVersion?: boolean;
    favorite?: boolean;
}>(), {
    cardIsClickable: true,
    hideFavButton: false,
    smallVersion: false,
    domains: undefined,
    categories: undefined,
    favorite: false
});

const emits = defineEmits(['updateFavorite']);

const { width } = useWindowSize();
const isDesktop = computed(() => width.value >= 768);
const isFavorite = ref(false);
const domainsValues = ref<Array<Domain>>([]);

const cardHandleClick = () => {
    if (props.cardIsClickable || !isDesktop.value)
        window.location.href = props.link
}

const getDomains = () => {
    if (props.domains) {
        domainsValues.value = props.domains;
    }
    else {
        axios.get(`/api/job-cards/${props.id}/domains`)
            .then(response => {
                domainsValues.value = response.data.data;
            })
            .catch(error => console.log(error))
    }
}

const getFavoriteStatus = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) return false;

    let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

    isFavorite.value = items.includes(props.id.toString());
}

const toggleFavorite = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId){
        const newSessionId = generateSessionCode();
        localStorage.setItem('sessionId', newSessionId)
        sessionId = newSessionId;
    }

    axios.post('/api/toggle-favorite', {
        jobcard_id: props.id,
        session_id: sessionId
    })
        .then(response => {
            emits('updateFavorite')
            isFavorite.value = response.data.wasAdded

            // Save list of liked fiches metier in local storage
            let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

            if (response.data.wasAdded) {
                if (!items.includes(response.data.jocardId)) {
                    items.push(response.data.jocardId);
                }
            } else {
                items = items.filter((item: string) => item !== response.data.jocardId);
            }

            localStorage.setItem('session_liked_items', JSON.stringify(items));
        })
        .catch(error => console.log(error))
}

onMounted(() => {
    getDomains()

    if (props.favorite){
        isFavorite.value = props.favorite
    }
    else {
        getFavoriteStatus()
    }
})
</script>

<template>
    <div class="jobcard" :class="cardIsClickable ? 'cursor-pointer':''" @click="cardHandleClick">
        <button
            v-if="!hideFavButton"
            class="w-10 h-10 bg-white rounded-full absolute z-20 right-3 top-3 flex items-center justify-center
                border border-gray-light transition duration-300 like-icon"
            :class="isFavorite ? 'text-white like-icon-active' : ''"
            @click.stop @click="toggleFavorite"
        >
            <SvgIcon name="heart" class="w-4" />
        </button>
        <img class="w-full rounded-t-[16px] object-cover" :class="smallVersion ? 'h-26 md:h-36' : 'h-48'" :src="image" alt="">
        <div class="p-4 bg-white rounded-tl-3xl rounded-tr-3xl -mt-4 z-20 relative">
            <h5
                class="font-medium tracking-tight text-gray-900 line-clamp-2 md:min-h-[60px]"
                :class="smallVersion ? 'text-md md:text-xl mb-2' : 'text-xl xl:text-2xl mb-4'"
            >
                {{ title }}
            </h5>

            <hr class="my-3 border-gray-light">

            <div class="flex items-center gap-4">
                <div
                    v-for="domain in domainsValues" :key="domain.id"
                    class="flex gap-1 items-center mt-2 border rounded-full px-2 py-0.5 border-gray-light"
                >
                    <div class="w-1.5 h-1.5 rounded-full" :style="'background: ' + domain.color" />
                    <div class="text-sm uppercase font-semibold">
                        {{ domain.title }}
                    </div>
                </div>
            </div>

            <a
                :href="link" class="mt-5 flex fill-black font-semibold gap-2 items-center hover:text-primary-light
                transition duration-150"
                @click.stop
            >
                Voir la fiche
                <SvgIcon name="arrowcircleright" class="w-5" />
            </a>
        </div>
    </div>
</template>

<style scoped>
.jobcard {
    @apply bg-white block w-full transition duration-300 relative select-none overflow-hidden h-fit;
    border-radius: 24px;
    border: 1px solid #EDEDED;
}

.jobcard:hover{
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.02);
    transform: translateY(-5px);
}
</style>
