<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import axios from "axios";
import FicheMetier from '@/components/JobCard.vue'
import { JobCard } from "@/interfaces/JobCard";
import Modal from "@/components/Modal.vue";
import JobCardSkeleton from '@/components/JobCardSkeleton.vue'
import Sharing from '@/components/FicheMetier/Header/Sharing.vue'
import NoFavoriteAnimation from "@/components/Lottie/NoFavoriteAnimation.vue";
import { generateSessionCode } from '@/utils'

const isLoaded = ref(false);
const sessionId: Ref<string|null> = ref(null);
const showConfirmNewSessionModal = ref(false);
const showLoadSessionModal = ref(false);
const loadSessionId: Ref<string|null> = ref(null);
const loadSessionError = ref(false);
const ficheMetiers = ref<Array<JobCard>>([])

const getSessionId = () => {
    return localStorage.getItem('sessionId');
}

const createNewSession = () => {
    ficheMetiers.value = [];
    showConfirmNewSessionModal.value = false;
    isLoaded.value = true;

    const newSessionId = generateSessionCode();
    sessionId.value = newSessionId;
    localStorage.setItem('sessionId', newSessionId)

    localStorage.setItem('session_liked_items', JSON.stringify([]));
}

const getFavFicheMetiers = () => {
    isLoaded.value = false;
    ficheMetiers.value = [];

    axios.post('/api/get-favoris', { session_id: sessionId.value })
        .then(response => {
            ficheMetiers.value = response.data.fiches_metiers;
        })
        .catch(error => console.log(error))
        .finally(() => isLoaded.value = true)
}

const loadSession = () => {
    loadSessionError.value = false;

    if (loadSessionId.value && loadSessionId.value.startsWith('#')) {
        loadSessionId.value = loadSessionId.value.substring(1);
    }

    axios.post('/api/load-session', { session_id: loadSessionId.value })
        .then(response => {
            ficheMetiers.value = response.data.fiches_metiers;
            sessionId.value = loadSessionId.value;
            loadSessionId.value = null;
            localStorage.setItem('sessionId', sessionId.value ?? '')
            showLoadSessionModal.value = false;

            // Set local storage favorite fiche metier
            const ids = ficheMetiers.value.map(fiche => fiche.id);
            localStorage.setItem('session_liked_items', JSON.stringify(ids));
        })
        .catch(error => {
            if (error.response.status === 403){
                loadSessionError.value = true;
            }
        })
}

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlSessionId = urlParams.get('session_id');
    sessionId.value = getSessionId();

    // Clean the url
    history.replaceState(null, '', `${location.pathname}`);

    if (urlSessionId && urlSessionId !== sessionId.value) {
        // If there is a session ID in the URL and it's different from the current session ID, attempt to load it
        loadSessionId.value = urlSessionId;
        loadSession();
    } else if (!sessionId.value) {
        // If there is no session ID, create a new session
        createNewSession();
    } else {
        // If the session ID from URL is the same as the current, just load the favorites
        getFavFicheMetiers();
    }
});
</script>

<template>
    <div class="container">
        <div class="flex gap-8">
            <h1 class="mb-4">
                Favoris
            </h1>
            <span
                v-if="ficheMetiers.length"
                class="uppercase block h-fit bg-primary-extra-light px-4 py-3 rounded-full font-medium font-sans"
            >
                #{{ sessionId }}
            </span>
        </div>

        <p v-if="ficheMetiers.length" class="my-6">
            <span class="font-sans uppercase">#{{ sessionId }}</span>
            est le code vous permettant en tout temps de retrouver ou de partager vos favoris.
            N’oubliez pas de bien le conserver !
        </p>
        <p v-else>
            Vous pouvez ajouter un favori depuis le catalogue ou directement depuis chaque fiche métier.
        </p>

        <div class="flex flex-col md:flex-row gap-4 justify-between mt-4">
            <div class="flex flex-col md:flex-row gap-4">
                <button-secondary class="px-6 font-medium" @click="showLoadSessionModal = true">
                    Ouvrir votre liste
                </button-secondary>
                <Modal
                    :is-visible="showLoadSessionModal" title="Ouvrir votre liste de favoris"
                    @close="showLoadSessionModal = false"
                >
                    <form @submit.prevent="loadSession">
                        <div>
                            <label
                                for="codesession"
                                class="block mb-2 text-sm font-medium"
                                :class="loadSessionError ? 'text-red-700 ' : ''"
                            >
                                Code
                            </label>
                            <input
                                id="codesession" v-model="loadSessionId" type="text" required
                                class="border text-sm rounded-lg block w-full p-2.5 font-medium placeholder:font-medium
                                placeholder:text-gray-medium bg-white border-gray-light focus:ring-primary-extra-light
                                focus:border-primary-light transition duration-250"
                                :class="loadSessionError
                                    ? 'bg-red-50 border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : ''"
                                placeholder="#16613QYK"
                            >
                            <p v-if="loadSessionError" class="mt-2 text-sm text-red-600 dark:text-red-500">
                                Aucune liste trouvée
                            </p>
                        </div>

                        <div class="pt-6">
                            <button-primary class="px-6 font-medium">
                                Ouvrir votre liste
                            </button-primary>
                        </div>
                    </form>
                </Modal>

                <button-primary v-if="ficheMetiers.length" class="px-6 font-medium" @click="showConfirmNewSessionModal = true">
                    Créer une nouvelle liste
                </button-primary>
                <Modal :is-visible="showConfirmNewSessionModal" title="Etes-vous sûr" @close="showConfirmNewSessionModal = false">
                    <p>
                        Souhaitez-vous vraiment créer une nouvelle liste ? Le seul moyen de récupérer votre liste actuelle
                        est de conserver soigneusement le code :
                    </p>
                    <p class="text-xl font-semibold">
                        #{{ sessionId }}
                    </p>

                    <div class="grid grid-cols-2 gap-2 pt-6">
                        <button-secondary class="px-6 font-medium" @click="showConfirmNewSessionModal=false">
                            Annuler
                        </button-secondary>
                        <button-primary class="px-6 font-medium" @click="createNewSession">
                            Créer une nouvelle liste
                        </button-primary>
                    </div>
                </Modal>
            </div>

            <Sharing
                v-if="ficheMetiers.length"
                title="Partager ma liste de favoris"
                :url-parameter="'?session_id=' + sessionId"
                :share-social="false"
            />
        </div>

        <hr class="my-12">

        <div>
            <div class="flex items-center gap-4">
                <h3 class="text-3xl">
                    Fiches métiers
                </h3>
                <span class="border h-fit border-gray-light rounded-full px-4 font-medium py-1">
                    {{ ficheMetiers?.length ?? 0 }}
                </span>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pt-4">
                <template v-if="ficheMetiers.length > 0">
                    <FicheMetier
                        v-for="ficheMetier in ficheMetiers"
                        :id="ficheMetier.id"
                        :key="ficheMetier.id"
                        :title="ficheMetier.title"
                        :image="ficheMetier.cover"
                        :link="ficheMetier.link"
                        :domains="ficheMetier.domains"
                        :favorite="true"
                        @update-favorite="getFavFicheMetiers"
                    />
                </template>
                <template v-else-if="!isLoaded">
                    <JobCardSkeleton v-for="index in 3" :key="index" />
                </template>
                <div v-else class="col-span-4">
                    <NoFavoriteAnimation />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
