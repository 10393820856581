<script setup lang="ts">

</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 32 40" fill="none">
        <rect x="1" y="0.5" width="30" height="39" rx="3.5" fill="white" stroke="#E6E6E6" />
        <rect x="5.30078" y="20.0005" width="6.17057" height="1.53846" fill="#D9D9D9" />
        <rect x="5.30078" y="23.8467" width="20.5686" height="1.53846" fill="#D9D9D9" />
        <rect x="5.30078" y="26.9233" width="13.7124" height="1.53846" fill="#D9D9D9" />
        <rect x="5.30078" y="33.0771" width="4.11371" height="1.53846" fill="#D9D9D9" />
        <rect x="3.5" y="3" width="25" height="14" rx="2" fill="url(#paint0_radial_2183_4285)" />
        <path d="M18.572 8.15754L16.614 6.08654C16.588 6.05907 16.5571 6.03729 16.5232 6.02244C16.4892 6.0076 16.4528 5.99997 16.4161 6H13.0594C12.9111 6 12.7688 6.06234 12.6639 6.17331C12.5589 6.28428 12.5 6.43478 12.5 6.59172V13.1006C12.5 13.2575 12.5589 13.408 12.6639 13.519C12.7688 13.63 12.9111 13.6923 13.0594 13.6923H18.0944C18.2428 13.6923 18.3851 13.63 18.49 13.519C18.5949 13.408 18.6538 13.2575 18.6538 13.1006V8.36686C18.6539 8.328 18.6467 8.28951 18.6326 8.25359C18.6186 8.21768 18.598 8.18504 18.572 8.15754ZM16.6958 7.00999L17.699 8.07101H16.6958V7.00999ZM18.0944 13.1006H13.0594V6.59172H16.1364V8.36686C16.1364 8.44533 16.1658 8.52058 16.2183 8.57607C16.2707 8.63155 16.3419 8.66272 16.4161 8.66272H18.0944V13.1006ZM16.9755 10.142C16.9755 10.2205 16.9461 10.2957 16.8936 10.3512C16.8411 10.4067 16.77 10.4379 16.6958 10.4379H14.458C14.3839 10.4379 14.3127 10.4067 14.2602 10.3512C14.2078 10.2957 14.1783 10.2205 14.1783 10.142C14.1783 10.0635 14.2078 9.98829 14.2602 9.93281C14.3127 9.87732 14.3839 9.84615 14.458 9.84615H16.6958C16.77 9.84615 16.8411 9.87732 16.8936 9.93281C16.9461 9.98829 16.9755 10.0635 16.9755 10.142ZM16.9755 11.3254C16.9755 11.4039 16.9461 11.4792 16.8936 11.5346C16.8411 11.5901 16.77 11.6213 16.6958 11.6213H14.458C14.3839 11.6213 14.3127 11.5901 14.2602 11.5346C14.2078 11.4792 14.1783 11.4039 14.1783 11.3254C14.1783 11.247 14.2078 11.1717 14.2602 11.1162C14.3127 11.0608 14.3839 11.0296 14.458 11.0296H16.6958C16.77 11.0296 16.8411 11.0608 16.8936 11.1162C16.9461 11.1717 16.9755 11.247 16.9755 11.3254Z" fill="white" />
        <defs>
            <radialGradient id="paint0_radial_2183_4285" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.2469 3.15564) rotate(90) scale(13.65 30.6826)">
                <stop stop-color="#FFA471" />
                <stop offset="1" stop-color="#FFA471" stop-opacity="0.6" />
            </radialGradient>
        </defs>
    </svg>
</template>

<style scoped>

</style>
