<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { Page } from "@/interfaces/Page";
import SvgIcon from "@/components/SvgIcon.vue";

const props = defineProps<{
    pages: Array<Page>,
}>();

const showMenu = ref(false);

const toggleMenu = () => {
    showMenu.value = !showMenu.value;

    const body = document.querySelector('body')
    if (body && showMenu.value) {
        body.style.overflow = 'hidden';
    }else if (body){
        body.style.overflow = 'auto';
    }
};

const scrolled = ref(false);

const handleScroll = () => {
    scrolled.value = window.scrollY > 0;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);

    const loadingScreen = document.querySelector('#loading-screen');
    setTimeout(() => {
        loadingScreen?.classList.add('opacity-0');
        setTimeout(() => {
            loadingScreen?.classList.add('hidden')
        }, 750)
    }, 250)
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <nav
        class="relative border-b"
        :class="(pages.find(page => page.id === 'home')?.is_current
            || pages.find(page => page.id === 'home')?.is_parent)
            && !scrolled
            ? 'border-b-transparent bg-transparent' : 'border-b-gray-light bg-white'"
    >
        <div
            class="z-40 p-4 max-w-screen-xl mx-auto flex items-center justify-between h-20 lg:h-[100px] overflow-x-clip"
        >
            <!-- Logo à gauche -->
            <img src="/assets/dev/logo.png" alt="Logo" class="z-40 h-12 lg:h-16 mr-4">

            <!-- Liens de navigation -->
            <div class="z-40 hidden lg:flex items-center space-x-4">
                <a
                    v-for="page in pages"
                    :key="page.title"
                    :href="page.url"
                    class="text-black hover:text-primary bg-gray-extra-light px-4 py-2 rounded-full font-semibold
                    border border-gray-light hover:border-primary transition duration-250 min-w-[120px] text-center"
                    aria-current="page"
                    :class="{
                        'text-primary': page.is_current || page.is_parent ,
                        'border-primary': page.is_current || page.is_parent
                    }"
                >
                    {{ page.title }}
                </a>
            </div>

            <!-- Bouton de menu pour mobile -->
            <button
                onclick="
                    this.classList.toggle('opened');
                    this.setAttribute('aria-expanded',
                    this.classList.contains('opened'))
                "
                aria-label="Main Menu"
                class="z-20 flex lg:hidden menu"
                @click="toggleMenu"
            >
                <SvgIcon name="hamburgermenu" class="w-10 h-10 fill-current text-gray-medium" />
            </button>

            <!-- Menu mobile -->
            <Transition>
                <div
                    v-if="showMenu"
                    class="z-10 flex flex-col justify-between space-y-4 lg:hidden absolute top-0 pt-20 left-0 bottom-0 right-0
                    w-full bg-white h-[100dvh]"
                >
                    <div class="border border-t-gray-light border-b-0 border-x-0">
                        <div class="px-4 flex flex-col items-center justify-center space-y-4 pt-8">
                            <a
                                v-for="page in pages"
                                :key="page.title"
                                :href="page.url"
                                class="text-black hover:text-primary bg-gray-extra-light px-4 py-3 rounded-2xl font-semibold
                                border border-gray-light hover:border-primary-light hover:border-2 w-full"
                                :class="{
                                    'text-primary': page.is_current || page.is_parent,
                                    'border-primary-light border-2': page.is_current || page.is_parent
                                }"
                            >
                                {{ page.title }}
                            </a>
                        </div>
                    </div>
                    <div class="px-4 flex flex-col gap-4">
                        <div class="flex flex-row gap-6">
                            <a target="_blank" href="https://www.tiktok.com/@citemetiers.expo">
                                <SvgIcon name="tiktok" class="fill-current shrink-0 w-6 h-6" />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/le28_geneve_santesocial/">
                                <SvgIcon name="instagram" class="fill-current shrink-0 w-6 h-6" />
                            </a>
                            <a
                                target="_blank"
                                href="https://fr.linkedin.com/posts/ortra-sant%C3%A9-social-gen%C3%A8ve-94ba9096_villagesante
                            social-balexert-m%C3%A9tier-activity-7151133644693069825-k9mN"
                            >
                                <SvgIcon name="linkedin" class="fill-current shrink-0 w-6 h-6" />
                            </a>
                            <a target="_blank" href="https://www.facebook.com/le28geneve/?locale=fr_FR">
                                <SvgIcon name="facebook" class="fill-current shrink-0 w-6 h-6" />
                            </a>
                            <a target="_blank" href="https://www.youtube.com/@genevesantesocial127">
                                <SvgIcon name="youtube" class="fill-current shrink-0 w-6 h-6" />
                            </a>
                        </div>
                        <hr class="bg-gray-light">
                        <div class="flex flex-row justify-end pb-8">
                            <p class="text-gray-medium text-sm">
                                © 2024 — Copyright
                            </p>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    </nav>
</template>

<style>
.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}
.line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}
.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}
.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}
.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}
.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
</style>
