<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'

const props = defineProps<{
    isVisible: boolean,
    title: string
}>();

const emit = defineEmits(['close']);

const closeModal = () => {
    emit('close', false);
};

const onEscapePress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        closeModal();
    }
};

onMounted(() => {
    document.addEventListener('keydown', onEscapePress);
});

onUnmounted(() => {
    document.removeEventListener('keydown', onEscapePress);
});
</script>

<template>
    <Transition>
        <div v-if="isVisible" class="modal-bg">
            <div
                tabindex="-1"
                role="dialog"
                class="modal"
                @click="closeModal"
            >
                <div class="relative p-4 w-full max-w-2xl max-h-full" @click.stop>
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow">
                        <!-- Modal header -->
                        <div class="flex items-center justify-between px-4 md:px-8 pt-4 pb-2 md:pt-6">
                            <h2 class="text-3xl text-gray-900">
                                {{ title }}
                            </h2>
                            <button
                                type="button"
                                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm
                                w-8 h-8 ms-auto inline-flex justify-center items-center"
                                @click="closeModal"
                            >
                                <svg
                                    class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <!-- Modal body -->
                        <div class="p-4 md:p-8 space-y-4">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.modal-bg {
    @apply fixed left-0 top-0 w-full h-full overflow-hidden z-50;
    background: rgb(0,0,0,0.5);
}
.modal{
    @apply fixed top-1/2 transform -translate-y-1/2 left-0 z-50 justify-center items-center w-full h-[calc(100%-1rem)]
    max-h-full flex overflow-y-auto overflow-x-hidden;
}
</style>
