<script setup lang="ts">
import SvgIcon from '@/components/SvgIcon.vue'
import Modal from '@/components/Modal.vue'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import QrcodeVue from 'qrcode.vue'
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import { computed, ref } from 'vue'

const props = withDefaults(defineProps<{
    title: string;
    shareSocial?: boolean;
    urlParameter?: string;
}>(), {
    shareSocial: true,
    urlParameter: ''
});

const currentUrl = computed(() => window.location.href + props.urlParameter);
const shareModalStatus = ref(false);
</script>

<template>
    <SecondaryButton class="gap-2 font-semibold px-3.5 w-fit !text-sm md:!h-[48px] !h-[44px]" @click="shareModalStatus = true">
        Partager
        <SvgIcon class="w-5" name="share" />
    </SecondaryButton>

    <Modal :title="title" :is-visible="shareModalStatus" @close="shareModalStatus = false">
        <div class="flex flex-col md:flex-row gap-8 items-center justify-between">
            <div class="text-center border border-gray-light rounded-xl p-4 w-fit">
                <QrcodeVue :value="currentUrl" :size="170" render-as="svg" foreground="#1A201E" />
                <span class="text-medium text-lg block mt-4">Qr code</span>
            </div>

            <span class="font-medium text-lg">ou</span>

            <div>
                <PrimaryButton
                    target="_blank" type="link" :href="'mailto:?&body=' + currentUrl"
                    class="px-5 gap-2 mx-auto mb-2"
                >
                    Envoyer par email
                    <SvgIcon class="w-6" name="send" />
                </PrimaryButton>
                <a
                    :href="currentUrl" class="underline text-sm underline-offset-4 max-w-[270px] text-center
                        break-words block"
                >
                    {{ currentUrl }}
                </a>
            </div>
        </div>

        <div v-if="shareSocial">
            <p class="mt-6 text-xl font-medium">
                Partager via les réseaux
            </p>
            <div class="flex mt-3 gap-8">
                <a :href="'https://api.whatsapp.com/send/?text=' + currentUrl" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M42.1875 0H7.8125C3.49778 0 0 3.49778 0 7.8125V42.1875C0 46.5022 3.49778 50 7.8125 50H42.1875C46.5022 50 50 46.5022 50 42.1875V7.8125C50 3.49778 46.5022 0 42.1875 0Z" fill="#25D366" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.75 16.0938C31.4063 13.75 28.2812 12.5 25 12.5C18.125 12.5 12.5 18.125 12.5 25C12.5 27.1875 13.125 29.375 14.2188 31.25L12.5 37.5L19.0625 35.7812C20.9375 36.7188 22.9688 37.3438 25 37.3438C31.875 37.3438 37.5 31.7188 37.5 24.8437C37.5 21.5625 36.0938 18.4375 33.75 16.0938ZM25 35.3125C23.125 35.3125 21.25 34.8438 19.6875 33.9063L19.375 33.75L15.4687 34.8437L16.5625 31.0938L16.25 30.625C15.1562 28.9062 14.6875 27.0313 14.6875 25.1563C14.6875 19.5313 19.375 14.8438 25 14.8438C27.8125 14.8438 30.3125 15.9375 32.3438 17.8125C34.375 19.8437 35.3125 22.3438 35.3125 25.1563C35.3125 30.625 30.7812 35.3125 25 35.3125ZM30.625 27.5C30.3125 27.3438 28.75 26.5625 28.4375 26.5625C28.125 26.4062 27.9687 26.4063 27.8125 26.7188C27.6562 27.0313 27.0313 27.6562 26.875 27.9687C26.7188 28.125 26.5625 28.125 26.25 28.125C25.9375 27.9688 25 27.6562 23.75 26.5625C22.8125 25.7812 22.1875 24.6875 22.0313 24.375C21.875 24.0625 22.0313 23.9062 22.1875 23.75C22.3438 23.5938 22.5 23.4375 22.6562 23.2812C22.8125 23.125 22.8125 22.9688 22.9688 22.8125C23.125 22.6563 22.9688 22.5 22.9688 22.3438C22.9688 22.1875 22.3438 20.625 22.0313 20C21.875 19.5313 21.5625 19.5312 21.4063 19.5312C21.25 19.5312 21.0937 19.5312 20.7812 19.5312C20.625 19.5312 20.3125 19.5312 20 19.8437C19.6875 20.1562 18.9063 20.9375 18.9063 22.5C18.9063 24.0625 20 25.4688 20.1562 25.7812C20.3125 25.9375 22.3437 29.2188 25.4687 30.4688C28.125 31.5625 28.5938 31.25 29.2188 31.25C29.8438 31.25 31.0938 30.4688 31.25 29.8438C31.5625 29.0625 31.5625 28.4375 31.4063 28.4375C31.25 27.6563 30.9375 27.6563 30.625 27.5Z" fill="white" />
                    </svg>
                </a>
                <a :href="'https://twitter.com/share?text=' + currentUrl" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M42.1875 0H7.8125C3.49778 0 0 3.49778 0 7.8125V42.1875C0 46.5022 3.49778 50 7.8125 50H42.1875C46.5022 50 50 46.5022 50 42.1875V7.8125C50 3.49778 46.5022 0 42.1875 0Z" fill="#1DA1F2" />
                        <path d="M37.5 17.1875C36.5625 17.6562 35.625 17.8125 34.5312 17.9688C35.625 17.3438 36.4062 16.4062 36.7188 15.1562C35.7812 15.7812 34.6875 16.0938 33.4375 16.4062C32.5 15.4687 31.0938 14.8438 29.6875 14.8438C26.4062 14.8438 23.9062 17.9688 24.6875 21.0938C20.4688 20.9375 16.7188 18.9063 14.0625 15.7813C12.6563 18.125 13.4375 21.0938 15.625 22.6562C14.8438 22.6562 14.0625 22.3438 13.2812 22.0313C13.2812 24.375 15 26.5625 17.3438 27.1875C16.5625 27.3437 15.7813 27.5 15 27.3438C15.625 29.375 17.5 30.9375 19.8437 30.9375C17.9687 32.3437 15.1562 33.125 12.5 32.8125C14.8438 34.2188 17.5 35.1562 20.3125 35.1562C29.8438 35.1562 35.1562 27.1875 34.8437 19.8437C35.9375 19.2187 36.875 18.2812 37.5 17.1875Z" fill="white" />
                    </svg>
                </a>
            </div>
        </div>
    </Modal>
</template>
