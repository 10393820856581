<script setup lang="ts">
import { onMounted } from 'vue'
import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination';
import { Testimonial } from '@/interfaces/Testimonial';

const props = defineProps<{
    testimonials: Array<Testimonial>,
}>();

onMounted(() => {
    new Swiper('.swiper', {
        modules: [Pagination, Autoplay],
        loop: true,
        spaceBetween: 20,
        autoplay: {
            delay: 10000,
        },
        pagination: {
            el: '.swiper-pagination',
        },
    });
})
</script>

<template>
    <div class="swiper w-full -mb-[40px]">
        <div class="swiper-wrapper h-fit">
            <!-- Slides -->
            <div
                v-for="(testimonial, index) in testimonials" :key="index"
                class="swiper-slide bg-white border border-gray-light rounded-[16px] overflow-hidden !h-fit"
            >
                <div class="flex h-full w-full p-4 md:p-0">
                    <img
                        v-if="testimonial.cover"
                        class="w-24 hidden md:block shrink-0 rounded-lg md:rounded-none md:w-1/3 h-auto object-cover
                        max-h-[500px]"
                        :src="testimonial.cover.permalink" alt=""
                    >
                    <div class="pl-3 md:p-4">
                        <p v-html="testimonial.content" />
                        <div class="mt-8">
                            <p>{{ testimonial.author_name }}</p>
                            <a :href="testimonial.author_company" target="_blank" class="text-gray-medium">
                                {{ testimonial.author_company }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="swiper-pagination" />
    </div>
</template>

<style>
.swiper {
    padding-bottom: 40px !important;
}
blockquote {
    @apply italic;
}
.swiper-pagination-bullet-active{
    background: #333333;
}
 [disabled] .slider-handle,
 [disabled].slider-target{
     cursor: auto!important;
 }
</style>
