<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import OpenFilterButton from './OpenFilterButton.vue'
import SearchForm from './SearchForm.vue'
import Filters from './Filters.vue'
import { FilterParameter } from '@/interfaces/FilterParameter'
import { Domain } from '@/interfaces/Domain'
import { Category } from '@/interfaces/Category'
import { JobCard } from '@/interfaces/JobCard'
import FicheMetier from '@/components/JobCard.vue'
import axios, { AxiosError } from 'axios'
import JobCardSkeleton from '@/components/JobCardSkeleton.vue'
import NoResultAnimation from '@/components/Lottie/NoResultAnimation.vue'
import Preview from '@/components/Catalog/Preview.vue'
import { OnboardingFilter } from '@/interfaces/OnboardingFilter'
import { filterJobCardsBySpecification, getTaxonomies, mapValueByTaxonomy } from '@/utils/onboarding'
import { concat, filter, flatMap, isEmpty, map, orderBy, uniqBy } from 'lodash'
import OnboardingResume from '@/components/Catalog/OnboardingResume.vue'

type GetJobCardsResponse = {
    fiches_metiers: JobCard[]
}

const props = defineProps<{
    domains: Array<Domain>,
    categories: Array<Category>,
    getParameters: FilterParameter,
    onboarding?: Array<OnboardingFilter>
}>();

const jobCards: Ref<Array<JobCard>> = ref([])
const load: Ref<boolean> = ref(true)
const selectedFicheMetier: Ref<JobCard | undefined> = ref()
const openFilterSideNav = ref(false)
const onboardingActive = ref(props.onboarding ?? null)

const form: Ref<FilterParameter> = ref({
    q: '',
    domains: [],
    categories: []
})

const clickedOnFiche = (ficheMetier: JobCard) => {
    selectedFicheMetier.value = selectedFicheMetier.value?.id === ficheMetier.id ? undefined : ficheMetier;
}

const getJobCards = () => {
    window.scrollTo(0,0);

    const startTime = Date.now();
    load.value = true;
    selectedFicheMetier.value = undefined

    axios.get<GetJobCardsResponse>('/api/job-cards', {
        params: {
            q: form.value.q ?? '',
            domains: form.value.domains ?? [],
            categories: form.value.categories ?? []
        }
    })
        .then((response: any) => {
            jobCards.value = response.data.fiches_metiers ?? [];

            if (onboardingActive.value) {
                const preFilterCards = jobCards.value;
                const specFilteredCards = onboardingSpecificationsFilter();
                const skillsFilteredCards = onboardingSkillsFilter();

                // Combine all filtered cards into a single array
                const combinedFilteredCards = concat(preFilterCards, specFilteredCards, skillsFilteredCards);

                // Count occurrences of each job card based on its 'id'
                const cardOccurrences = combinedFilteredCards.reduce((acc: Record<string, number>, card) => {
                    acc[card.id] = (acc[card.id] || 0) + 1;
                    return acc;
                }, {} as Record<string, number>);

                // Remove duplicates and sort by occurrences
                const uniqueCards = uniqBy(combinedFilteredCards, 'id');
                jobCards.value = orderBy(uniqueCards, [(card) => cardOccurrences[card.id]], ['desc']);
            }
        })
        .catch((error: AxiosError) => {
            console.error(error);
        })
        .finally(() => {
            // Calculate the time remaining to reach 500ms. If already past 500ms, set to 0.
            const remainingTime = Math.max(0, 500 - (Date.now() - startTime));

            setTimeout(() => { load.value = false; }, remainingTime);
        });
}

const onboardingDomainsCategoriesFilter = () => {
    if (!props.onboarding) return

    form.value.domains = getTaxonomies(props.onboarding, 'domain', 5) as unknown as string[];
    form.value.categories = getTaxonomies(props.onboarding, 'category', 5) as unknown as string[];
}

const onboardingSpecificationsFilter = () => {
    const onboardingSpecificationFilters = map(
        filter(props.onboarding, { question_type: 'specification' }),
        mapValueByTaxonomy
    );

    if (isEmpty(onboardingSpecificationFilters)) return [];

    return uniqBy(
        flatMap(onboardingSpecificationFilters, filter =>
            filterJobCardsBySpecification(jobCards.value, filter.taxonomies[0] || '', filter.value)
        ),
        'id'
    );
}

const onboardingSkillsFilter = () => {
    if (!props.onboarding) return [];

    const skills = getTaxonomies(props.onboarding, 'skills', 5);
    const softSkills = getTaxonomies(props.onboarding, 'soft_skills', 5);

    // Filter job cards based on matching skills and soft skills
    return jobCards.value.filter(jobCard =>
        // Check if 'skills' is empty or if the job card has matching skills with a value >= 5
        (skills.length === 0 || jobCard.skills.some(skill =>
            skills.includes(skill.selected_skill.slug) && skill.skill_value >= 5
        )) &&

        // Check if 'softSkills' is empty or if the job card has matching soft skills with a value >= 5
        (softSkills.length === 0 || jobCard.soft_skills.some(softSkill =>
            softSkills.includes(softSkill.selected_soft_skill.slug) && softSkill.soft_skill_value >= 5
        ))
    );
}

const stopOnboarding = () => {
    onboardingActive.value = null;
    getJobCards()
}

onMounted(() => {
    form.value.q = props.getParameters?.q ?? '';

    onboardingDomainsCategoriesFilter()
    getJobCards()
})
</script>

<template>
    <div class="flex justify-between max-w-[100rem] mx-auto pt-12 mb-40">
        <Filters
            v-model:domains="form.domains"
            v-model:categories="form.categories"
            :is-open="openFilterSideNav"
            :domains-values="domains"
            :categories-values="categories"
            @close="openFilterSideNav = false"
            @submit="getJobCards"
        />

        <div class="w-full px-6 md:mt-[10px] overflow-x-clip">
            <OnboardingResume
                v-if="onboardingActive"
                :onboarding="onboardingActive"
                @stop-onboarding-filter="stopOnboarding"
            />

            <SearchForm v-model="form.q" @submit="getJobCards" />

            <OpenFilterButton @click="openFilterSideNav = true" />

            <div class="flex gap-4 md:mt-6">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full xl:grid-cols-3">
                    <div v-if="!load && jobCards.length < 1" class="col-span-4">
                        <NoResultAnimation />
                    </div>

                    <template v-if="load">
                        <JobCardSkeleton v-for="index in 3" :key="index" />
                    </template>

                    <template v-else>
                        <FicheMetier
                            v-for="ficheMetier in jobCards"
                            :id="ficheMetier.id"
                            :key="ficheMetier.id"
                            class="hover:cursor-pointer h-fit"
                            :class="selectedFicheMetier?.id === ficheMetier.id ? 'active-shadow' : '' "
                            :title="ficheMetier.title"
                            :image="ficheMetier.cover"
                            :link="ficheMetier.link"
                            :domains="ficheMetier.domains"
                            :card-is-clickable="false"
                            @click="clickedOnFiche(ficheMetier)"
                        />
                    </template>
                </div>

                <Preview :fiche-metier="selectedFicheMetier" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.active-shadow{
    box-shadow: 0 0 16px 1px rgba(241, 201, 155, 0.30)!important;
}
</style>
