<script setup lang="ts">
import { Event } from '@/interfaces/Event';
import { computed, Ref } from 'vue'
import moment from "moment";
import SvgIcon from "../SvgIcon.vue";
import { CalendarEvent } from '@/interfaces/CalendarEvent';
// @ts-ignore
import { Qalendar } from "qalendar";

const props = defineProps<{
    events: Array<Event>,
    dateStart: string | undefined,
}>();

const emit = defineEmits(['event-was-clicked','event-was-closed', 'date-was-clicked']);

moment.updateLocale('fr', {
    months: [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
        "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ]
});

const selectedDate: Ref<Date> = computed(() => {
    return props.dateStart != undefined ? new Date(props.dateStart) : new Date();
})

const mappedEvents: CalendarEvent[] = props.events.map(event => ({
    id: event.id.toString(),
    title: event.title,
    time: {
        start: moment(event.start_date).format("YYYY-MM-DD"),
        end: moment(event.end_date).format("YYYY-MM-DD")
    },
    colorScheme: 'default',
    isEditable: false,
    external_link: event.external_link,
    permalink: event.permalink,
    isCustom: true,
}));

const config = {
    defaultMode: "month",

    disableModes: ["day","week"],

    style: {
        colorSchemes: {
            default: {
                color: "#fff",
                backgroundColor: "#E0793F",
            },
        },
    },
    eventDialog:  {
        isCustom: true,
        closeOnClickOutside: true,
    },
    month: {
        showTrailingAndLeadingDates: false,
    },
}

const eventClicked = (event: { clickedEvent: CalendarEvent }) => {
    emit('event-was-clicked', event.clickedEvent);
}

const eventClosed = (closeEventDialog: () => void, clickedEvent: CalendarEvent) => {
    closeEventDialog();
    emit('event-was-closed', clickedEvent);
}

const dateClicked = (date: string) => {
    emit('date-was-clicked', date);
}
</script>

<template>
    <div class="calendar-container is-light-mode">
        <Qalendar
            class="is-light-mode"
            :events="mappedEvents"
            :config="config"
            :selected-date="selectedDate"
            @event-was-clicked="eventClicked"
            @date-was-clicked="dateClicked"
        >
            <template #dayCell="{dayData}">
                <div>
                    <div class="calendar-month__day-date">
                        {{ dayData.dateTimeString.substring(8, 10) }}
                    </div>
                    <div v-if="dayData.events.length" class="calendar-month__event">
                        {{ dayData.events.length }}
                    </div>
                </div>
            </template>

            <template #eventDialog="propsEvent">
                <div v-if="propsEvent.eventDialogData && propsEvent.eventDialogData.title" class="flex flex-col space-y-4 p-4">
                    <div class="w-full flex flex-row-reverse align-right">
                        <SvgIcon
                            class="w-4 h-4 text-gray-medium cursor-pointer" name="x"
                            @click="eventClosed(propsEvent.closeEventDialog, propsEvent.eventDialogData)"
                        />
                    </div>
                    <div class="flex flex-row space-x-4 items-center">
                        <p class="w-full text-lg md:text-xl font-medium">
                            {{ propsEvent.eventDialogData.title }}
                        </p>
                    </div>
                    <p
                        v-if="propsEvent.eventDialogData.time.start !== propsEvent.eventDialogData.time.end"
                        class="text-sm md:text-md text-medium-gray"
                    >
                        {{ moment(propsEvent.eventDialogData.time.start).format("DD MMMM YYYY") }} -
                        {{ moment(propsEvent.eventDialogData.time.end).format("DD MMMM YYYY") }}
                    </p>
                    <p v-else class="text-sm md:text-md text-medium-gray">
                        {{ moment(propsEvent.eventDialogData.time.start).format("DD MMMM YYYY") }}
                    </p>
                    <hr>
                    <div class="flex flex-col items-end w-full">
                        <a
                            :href="propsEvent.eventDialogData.external_link
                                ? propsEvent.eventDialogData.external_link
                                : propsEvent.eventDialogData.permalink"
                            :target="propsEvent.eventDialogData.external_link ? '_blank' : '_self'"
                            class="flex flex-row space-x-2 font-medium"
                        >
                            <span>Voir l'événement</span>
                            <SvgIcon name="arrowcircleright" fill="#5C5C5C" width="16" heigth="16" />
                        </a>
                    </div>
                </div>
            </template>
        </Qalendar>
    </div>
</template>

<style lang="scss">
@import "qalendar/dist/style.css";

$customColor: theme('colors.primary');
$lightPrimary: theme('colors.primary-light');
$extraLightPrimary: theme('colors.primary-extra-light');
$black: theme('colors.black');
$gray: theme('colors.gray-light');
$secondary: theme('colors.secondary');
$secondaryDark: theme('colors.secondary-dark');
$secondaryLight: theme('colors.secondary-light');

.calendar-root{
    background: white;
}

.qalendar-is-small .calendar-month__weekday {
    position: relative;
}

.calendar-month__day-date{
    background-color: transparent !important;
    font-size: 13px;
    margin: 1px;
}

.calendar-month__weekday.is-today .calendar-month__day-date {
    background-color: transparent !important;
    color: $customColor;
    font-weight: 500;
}

.qalendar-is-small .calendar-month__weekday.is-selected {
    box-shadow: inset 0 0 0 2px $lightPrimary !important;
    border-radius: 5px;
}

.agenda__wrapper .agenda__header .agenda__header-date {
    background-color: $secondary !important;
}

.agenda__wrapper .agenda__header .agenda__header-day-name {
    color: $secondaryDark !important;
}

.calendar-header__mode-picker {
    display: none !important;
}

.agenda__event-title {
    @apply text-sm md:text-base
}

.date-picker .week span.is-today {
    background-color: $extraLightPrimary !important;
}

.date-picker .week.is-active {
    border: 1px dashed $extraLightPrimary !important;
}

.event-flyout {
    box-shadow: 0 4px 57.6px -28px rgba(0, 0, 0, 0.10) !important;
}

.qalendar-is-small .calendar-month__event{
    @apply absolute text-[12px] w-[14px] h-[14px] flex justify-center items-center text-white rounded-full;
    top: 4px;
    right: 4px;
    background: $secondaryDark !important;
}

.calendar-month__weekday .calendar-month__day-date{
    left: 6px;
    @apply text-gray-600 bg-white top-0 absolute;
}

.agenda__content-events-list{
    border: 1px solid $gray;
    margin-bottom: 5px !important;
    border-radius: 8px;
    overflow: hidden;
}
.agenda__event{
    background: white !important;
    color: $black !important;
    padding: 15px !important;
    position: relative;
    &:hover{
        color: $secondaryDark!important;
    }
}
.agenda__event:before{
    content: "";
    width: 2px;
    height: 70%;
    background: $secondaryLight;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}
</style>
