<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import { debounce } from 'lodash'
import { PhMagnifyingGlass } from '@phosphor-icons/vue'

const props = defineProps<{
    modelValue: string,
}>();

const emit = defineEmits(['update:modelValue', 'submit'])

const debouncedSubmit = debounce(() => {
    emit('submit');
}, 500);

const handleInput = ($event: Event) => {
    const value = ($event.target as HTMLInputElement).value;
    emit('update:modelValue', value);
    debouncedSubmit();
}

const submit = () => {
    emit('submit')
}
</script>

<template>
    <form @submit.prevent="submit">
        <label for="default-search" class="sr-only">Search</label>
        <div class="relative flex gap-2 md:gap-4">
            <div class="absolute hidden md:flex inset-y-0 start-0 items-center ps-3 pointer-events-none">
                <SvgIcon class="w-5 h-5 text-gray-medium" name="search" />
            </div>
            <input
                type="search"
                :value="props.modelValue"
                class="block w-full font-medium p-4 md:ps-10 placeholder:font-medium placeholder:text-gray-medium
                text-sm bg-white border border-gray-light rounded-[10px] focus:ring-primary-extra-light
                focus:border-primary-light"
                placeholder="Rechercher"
                @input="handleInput"
            >
            <PrimaryButton class="md:w-[170px] px-4">
                <PhMagnifyingGlass class="md:hidden" size="24" />
                <span class="hidden md:block">Rechercher</span>
            </PrimaryButton>
        </div>
    </form>
</template>

<style scoped>

</style>
