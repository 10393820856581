<script setup lang="ts">
import Tabs from '../../Tabs.vue'
import AccessPathTabs from './AccessPathTabs.vue'
import { Tab } from '@/interfaces/Tab';
import { SchoolExperienceInformation } from '@/interfaces/SchoolExperienceInformation';
import { Career } from '@/interfaces/Career';
import { computed } from 'vue'

const props = defineProps<{
    school: SchoolExperienceInformation,
    validationOfAcquiredExperience: SchoolExperienceInformation,
    professionalCareer: Array<Career>,
}>();


const tabList = computed(() => {
    const tabs: Tab[] = [];

    const displaySchool: boolean = Boolean(props.school.admission_requirements) || Boolean(props.school.degree_obtained)
        || Boolean(props.school.duration) || Boolean(props.school.locations);
    tabs.push({ title: "Parcours scolaire", icon: "graduationcap", display: displaySchool });

    const displayValidationOfAcquiredExperience: boolean = Boolean(props.validationOfAcquiredExperience.admission_requirements)
        || Boolean(props.validationOfAcquiredExperience.degree_obtained) || Boolean(props.validationOfAcquiredExperience.duration)
        || Boolean(props.validationOfAcquiredExperience.locations);
    tabs.push({ title: "Validations des acquis de l'expérience", icon: "checkcircle", variant: "secondary", display: displayValidationOfAcquiredExperience });

    tabs.push({ title: "Parcours pro", icon: "idbadge", variant: "tertiary", display: props.professionalCareer && props.professionalCareer.length > 0 });

    return tabs;
});
</script>

<template>
    <h2 class="mb-4">
        Voie d’accès
    </h2>

    <p class="mb-7">
        L'accès à un métier peut se réaliser par le biais de diverses voies. Tout d'abord, l'éducation formelle se
        déroule principalement dans les écoles professionnelles et les universités, proposant des programmes
        spécifiques adaptés aux métiers. En parallèle, la validation des acquis de l’expérience, qui reconnaît
        l'expérience professionnelle ou les connaissances acquises autrement, ouvre des portes vers certains métiers.
    </p>

    <Tabs :tab-list="tabList">
        <template #tab-1>
            <AccessPathTabs :data="school" variant="primary" />
        </template>

        <template #tab-2>
            <AccessPathTabs :data="validationOfAcquiredExperience" variant="secondary" />
        </template>

        <template #tab-3>
            <div class="flex flex-col gap-8">
                <div
                    v-for="(career, index) in professionalCareer" :key="index"
                    class="flex gap-4 md:gap-0 items-center justify-between"
                >
                    <a
                        :href="career.selected_fiche_metier.url"
                        class="md:w-1/3 flex flex-col md:flex-row gap-2 items-center font-medium text-center md:text-left
                        flex-wrap lg:flex-nowrap"
                    >
                        <img class="w-36 rounded-lg" :src="career.selected_fiche_metier.cover.url" alt="">
                        {{ career.selected_fiche_metier.title }}
                    </a>
                    <div class="text-5xl text-tertiary md:w-1/3 text-center">
                        +
                    </div>
                    <div class="wysiwyg md:w-1/3" v-html="career.additional_qualifications" />
                </div>
            </div>
        </template>
    </Tabs>
</template>

<style scoped>

</style>
