<script setup lang="ts">
import { gsap } from 'gsap'
import { onMounted } from 'vue'
import MotionPathPlugin from 'gsap/MotionPathPlugin';

function animateUserOnPath(userId: string, pathId: string, start: number, speed: number) {
    gsap.to(`#${userId}`, {
        duration: speed,
        ease: "none",
        motionPath: {
            path: `#${pathId}`,
            align: `#${pathId}`,
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            start: start,
            end: start+1
        },
        scale: 1,
        repeat: -1,
    });
}

onMounted(() => {
    gsap.registerPlugin(MotionPathPlugin);

    animateUserOnPath('user1', 'path3', 0.7, 60);
    animateUserOnPath('user6', 'path3', 0.93, 60);
    animateUserOnPath('user7', 'path3', 0.35, 60);
    animateUserOnPath('user2', 'path2', 0.1, 45);
    animateUserOnPath('user4', 'path2', 0.4, 45);
    animateUserOnPath('user5', 'path2', 0.8, 45);
    animateUserOnPath('user3', 'path1', 0.6, 30);
})
</script>


<template>
    <div>
        <div id="user1" class="user-1 h-10 w-10 bg-primary-light rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-1.png" class="object-cover" alt="">
        </div>
        <div id="user2" class="user-1 h-8 w-8 bg-secondary rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-2.png" class="object-cover" alt="">
        </div>
        <div id="user3" class="user-1 h-8 w-8 bg-primary-extra-light rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-3.png" class="object-cover" alt="">
        </div>
        <div id="user4" class="user-1 h-8 w-8 bg-primary-light rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-4.png" class="object-cover" alt="">
        </div>
        <div id="user5" class="user-1 h-8 w-8 bg-tertiary rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-5.png" class="object-cover" alt="">
        </div>
        <div id="user6" class="user-1 h-8 w-8 bg-secondary rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-1.png" class="object-cover" alt="">
        </div>
        <div id="user7" class="user-1 h-9 w-9 bg-tertiary rounded-full absolute overflow-hidden">
            <img src="/assets/dev/users/user-2.png" class="object-cover" alt="">
        </div>

        <svg class="w-full" viewBox="0 0 502 506" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="path3" d="M501 255C501 393.071 389.071 505 251 505C112.929 505 1 393.071 1 255C1 116.929 112.929 5 251 5C389.071 5 501 116.929 501 255Z" fill="url(#paint0_radial_2430_4117)" />
            <circle cx="250.984" cy="255.158" r="249.5" stroke="#C4C4C4" stroke-opacity="0.75" />
            <path id="path2" d="M413.818 254.742C413.818 344.442 341.101 417.158 251.401 417.158C161.701 417.158 88.9844 344.442 88.9844 254.742C88.9844 165.041 161.701 92.325 251.401 92.325C341.101 92.325 413.818 165.041 413.818 254.742Z" stroke="#C4C4C4" stroke-opacity="0.75" />
            <path id="path1" d="M344.65 255.158C344.65 306.889 302.714 348.825 250.983 348.825C199.252 348.825 157.316 306.889 157.316 255.158C157.316 203.428 199.252 161.492 250.983 161.492C302.714 161.492 344.65 203.428 344.65 255.158Z" stroke="#C4C4C4" stroke-opacity="0.75" />
            <defs>
                <radialGradient id="paint0_radial_2430_4117" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(309.88 226.398) rotate(87.0202) scale(269.243 269.537)">
                    <stop stop-color="#FB8142" stop-opacity="0.08" />
                    <stop offset="1" stop-color="#FB8142" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    </div>
</template>

<style scoped>

</style>
