<script setup lang="ts">
import { computed } from "vue";
import { News } from '@/interfaces/News';
import { Category } from '@/interfaces/Category';
import { FilterParameter } from '@/interfaces/FilterParameter';
import { useFilters } from '@/composables/useFilters';
import FiltersContainer from '@/components/Filters/FiltersContainer.vue'
import NewsInformations from '@/components/News/NewsInformations.vue'
import { Domain } from '@/interfaces/Domain'
import NoResultAnimation from '@/components/Lottie/NoResultAnimation.vue'

const props = defineProps<{
    news: Array<News>,
    domains: Array<Domain>,
    categories: Array<Category>,
    getParameters: FilterParameter,
}>();

const handleSubmit = (data: FilterParameter) => {
    useFilters().handleSubmit(data);
}

const categoriesOptions = computed(() => props.categories.map(
    category => ({ value: category.slug, label: category.title })
));

const domainsOptions = computed(() => props.domains.map(
    domain => ({ value: domain.slug, label: domain.title })
));
</script>

<template>
    <div class="container flex flex-col gap-8 mb-20">
        <h1>
            Les dernières actualités
        </h1>
        <FiltersContainer
            :domains-options="domainsOptions"
            :categories-options="categoriesOptions"
            :get-parameters="props.getParameters"
            placeholder-search="Rechercher une actualité"
            @submit="handleSubmit"
        />
        <div class="w-full mt-6">
            <div v-if="news.length > 0" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                <NewsInformations v-for="(n) in news" :key="n.title" :news="n" />
            </div>
            <p v-else>
                <NoResultAnimation />
            </p>
        </div>
    </div>
</template>
