<script setup lang="ts">
import { onMounted, Ref, ref, UnwrapRef } from 'vue'
import Multiselect from '@vueform/multiselect'
import { format } from "date-fns";
import SvgIcon from "../SvgIcon.vue";
import { FilterParameter } from '@/interfaces/FilterParameter';
import { SelectOption } from '@/interfaces/SelectOption';
import { Dates } from '@/interfaces/Dates';
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import Modal from '@/components/Modal.vue'
// @ts-ignore
import DateRangePicker from 'vue3-daterange-picker'

const props = defineProps<{
    domainsOptions: Array<SelectOption>,
    categoriesOptions: Array<SelectOption>,
    getParameters: FilterParameter,
    placeholderSearch: string,
}>();

const emit = defineEmits([
    'update:search',
    'update:start_date',
    'update:end_date',
    'update:domains',
    'update:categories',
    'submit'
])

const search = ref('');
const dateRangeFormat: Ref<Dates> = ref({
    startDate: "",
    endDate: "",
});
const domains: Ref<string[]> = ref([]);
const categories: Ref<string[]> = ref([]);
const focus: Ref<boolean> = ref(false);
const filtersModal = ref(false);

// get params from url
onMounted(() => {
    search.value = props.getParameters?.search ?? '';
    domains.value = props.getParameters?.domains ?? [];
    categories.value = props.getParameters?.categories ?? [];
    dateRange.value.start = props.getParameters?.start_date ? new Date(props.getParameters.start_date) : null;
    dateRange.value.end = props.getParameters?.end_date ? new Date(props.getParameters.end_date) : null;
    updateValues();
})

const submit = () => {
    emit('submit', {
        'search': search.value,
        'start_date': dateRangeFormat.value.startDate,
        'end_date': dateRangeFormat.value.endDate,
        'domains': domains.value,
        'categories': categories.value
    })
}

// date settings

// -- default
const dateRangeDefault: Ref<UnwrapRef<{ endDate: null; startDate: null }>> = ref({
    startDate: null,
    endDate: null
});

const dateRange: Ref<{ start: Date | null, end: Date | null }> = ref({
    start: null,
    end: null
});

const updateValues = (state?: boolean) => {
    focus.value = state ?? false

    if (dateRange.value.start instanceof Date && dateRange.value.end instanceof Date) {
        dateRangeFormat.value.startDate = format(dateRange.value.start, 'MM/dd/yyyy');
        dateRangeFormat.value.endDate = format(dateRange.value.end, 'MM/dd/yyyy');
    } else {
        dateRangeFormat.value.startDate = "";
        dateRangeFormat.value.endDate = "";
    }
}

let today = new Date()
today.setHours(0, 0, 0, 0)

let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)
yesterday.setHours(0, 0, 0, 0);

const clearDates = () => {
    dateRange.value.start = null;
    dateRange.value.end = null;
    updateValues();
};

const resetFilters = () => {
    clearDates();
    search.value = '';
    domains.value = [];
    categories.value = [];
}
</script>

<template>
    <form @submit.prevent="submit">
        <div
            class="grid grid-cols-1 md:grid-cols-12 px-1 bg-white rounded-[24px] border border-gray-300 gap-4
            transition duration-200 pb-1 md:pb-0"
            :class="focus ? 'border-primary-light' : ''"
        >
            <input
                v-model="search"
                type="search"
                class="col-span-1 md:col-span-4 block border-none w-text-sm text-gray-900 focus:ring-transparent
                focus:border-transparent placeholder-gray-400 my-2 ml-1"
                :placeholder="props.placeholderSearch"
                @focusin="focus = true" @focusout="focus = false"
                @input="emit('update:search', ($event.target as HTMLInputElement).value)"
            >
            <div class="col-span-1 md:col-span-3 flex flex-row gap-x-1 md:border-gray-300 md:border-x md:pl-4 px-3 my-2">
                <SvgIcon name="calendar" fill="#5C5C5C" width="16" heigth="16" />
                <DateRangePicker
                    ref="dateRange"
                    opens="right"
                    :time-picker="false"
                    format="mm/dd/yyyy"
                    :auto-apply="true"
                    :date-range="dateRangeDefault"
                    :locale-data="{
                        direction: 'ltr',
                        format: 'dd/mm/yyyy',
                        separator: ' - ',
                        daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                        monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                        firstDay: 1,
                    }"
                    :ranges="{
                        'Aujourd\'hui': [today, today],
                        'Hier': [yesterday, yesterday],
                        'Ce mois': [
                            new Date(today.getFullYear(), today.getMonth(), 1),
                            new Date(today.getFullYear(), today.getMonth() + 1, 0)
                        ],
                        'Cette année': [
                            new Date(today.getFullYear(), 0, 1),
                            new Date(today.getFullYear(), 11, 31)
                        ],
                        'Le mois dernier': [
                            new Date(today.getFullYear(), today.getMonth() - 1, 1),
                            new Date(today.getFullYear(), today.getMonth(), 0)
                        ],
                    }"
                    class="w-full"
                    @toggle="updateValues"
                >
                    <template #input="picker">
                        <div class="flex items-center">
                            <span v-if="dateRange.start === null || dateRange.end === null" class="text-gray-400">
                                Date
                            </span>
                            <span v-else>
                                {{ dateRangeFormat.startDate }} - {{ dateRangeFormat.endDate }}
                            </span>
                            <button v-if="dateRange.start !== null || dateRange.end !== null" class="ml-2" @click="clearDates">
                                <SvgIcon
                                    name="cross" class="fill-current text-gray-medium hover:text-[#000]" width="22" height="22"
                                />
                            </button>
                        </div>
                    </template>
                </DateRangePicker>
            </div>
            <div class="md:col-span-3 flex flex-row gap-x-1 pl-3 md:pl-0">
                <button type="button" class="gap-2 flex items-center my-2 rounded-lg w-full" @click="filtersModal = true">
                    <SvgIcon class="shrink-0" name="filter" fill="#5C5C5C" width="16" heigth="16" />
                    <span class="text-gray-400">Afficher les filtres</span>
                    <span
                        v-if="domains.length + categories.length > 0"
                        class="bg-primary-light px-2 rounded-full text-white text-sm"
                    >
                        {{ domains.length + categories.length }}
                    </span>
                </button>
                <Modal title="Filter les événements" :is-visible="filtersModal" @close="filtersModal = false">
                    <div>
                        <label class="mb-1 block">Domaines</label>
                        <Multiselect
                            v-model="domains"
                            :options="props.domainsOptions"
                            :close-on-select="false"
                            mode="tags"
                            placeholder="Domaines"
                            no-results-text="Pas de résultat"
                            @open="focus = true"
                            @close="focus = false"
                        />
                        <label class="mb-1 mt-4 block">Catégories</label>
                        <Multiselect
                            v-model="categories"
                            :options="props.categoriesOptions"
                            :close-on-select="false"
                            mode="tags"
                            placeholder="Catégories"
                            no-results-text="Pas de résultat"
                            @open="focus = true"
                            @close="focus = false"
                        />
                        <PrimaryButton class="px-8 mt-8" @click="submit">
                            Rechercher
                        </PrimaryButton>
                    </div>
                </Modal>
            </div>
            <div class="col-span-1 md:col-span-2 flex items-center">
                <PrimaryButton class="!h-[48px] !rounded-[18px]  w-full">
                    Rechercher
                </PrimaryButton>
            </div>
        </div>
        <button
            class="ml-auto flex w-fit text-sm mr-2 mt-2 text-gray-medium hover:text-primary underline underline-offset-2
            cursor-pointer"
            @click="resetFilters"
        >
            Réinitialiser
        </button>
    </form>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
$customColor: theme('colors.primary');

.form-control{
    border: none!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.multiselect-tag{
    background: $customColor !important;
}

:root{
    --ms-radius: 8px;
    --ms-ring-color: #F1C99B;
    --ms-ring-width: 2px;
    --ms-border-color-active: #F1C99B;
}

.daterangepicker td.active {
    background-color: $customColor !important;
    color: white !important;
}
</style>
