import { JobCard } from '@/interfaces/JobCard'
import { OnboardingFilter } from '@/interfaces/OnboardingFilter'

const getTaxonomies = (onboardings: Array<OnboardingFilter>, type: string, moreThan: number) => {
    return onboardings
        .filter(item => item.question_type === type && item.value > moreThan)
        .flatMap(item => item.taxonomies || []);
};

const filterJobCardsBySpecification = (jobCards: Array<JobCard>, specSlug: string, specValue: string) => {
    return jobCards.filter(job =>
        job.specifications.some(spec =>
            spec.selected_specification.slug === specSlug && spec.specification_value === specValue
        )
    );
};

const mapEmployabiliteValue = (score: number) => {
    if (score > 8) {
        return 'Excellente';
    } else if (score >= 6 && score <= 8) {
        return 'Bonne';
    } else if (score >= 4 && score < 6) {
        return 'Moyenne';
    } else {
        return 'Faible';
    }
};

const mapValueByTaxonomy = (item: any) => {
    if (item.taxonomies.includes('employabilite')) {
        return {
            ...item,
            value: mapEmployabiliteValue(item.value)
        };
    }

    return item;
};

export { mapEmployabiliteValue, mapValueByTaxonomy, getTaxonomies, filterJobCardsBySpecification };
