<script setup lang="ts">
import Tabs from '../../Tabs.vue'
import { Gesture } from '@/interfaces/Gesture';
import { Tab } from '@/interfaces/Tab';

const props = defineProps<{
    typicalDay?: string,
    everydayGestures: Array<Gesture>,
}>();

const tabList: Tab[] = [
    { title: 'Journée type', icon: 'sun' },
    { title: 'Gestes du quotidien', icon: 'handtap' },
]
</script>

<template>
    <h2 class="mb-7">
        Quotidien
    </h2>

    <Tabs :tab-list="tabList">
        <template #tab-1>
            <div class="wysiwyg" v-html="typicalDay" />
        </template>

        <template #tab-2>
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4 xl:gap-x-16 xl:gap-y-8">
                <div v-for="(gesture, index) in everydayGestures" :key="index">
                    <img class="w-full h-32 md:h-44 rounded-lg object-cover" :src="gesture.image?.url" alt="">
                    <span class="mt-2 block font-medium">{{ gesture.title }}</span>
                    <span class="text-sm block font">{{ gesture.copyright }}</span>
                </div>
            </div>
        </template>
    </Tabs>
</template>
