const convertYouTubeUrlToNoCookieEmbed = (url: string | null) => {
    if (!url) return '';

    // eslint-disable-next-line max-len
    const youtubeIdRegex = /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*?(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=|(?<username>user\/))(?<id>[\w\-]{11})(?:\?|&|$)/;
    const match = url.match(youtubeIdRegex);

    // checks if 'user/' is located right before expected id, in which case it would return username instead
    if (match?.groups?.username || !match?.groups?.id) return '';

    return `https://www.youtube.com/embed/${match.groups.id}`;
}

const generateSessionCode = () => {
    return `${Math.random().toString(36).slice(2, 4)}${(Date.now() % 100000).toString().slice(0,2)}`;
}

export { convertYouTubeUrlToNoCookieEmbed, generateSessionCode };
