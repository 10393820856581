<script setup lang="ts">

</script>

<template>
    <button class="btn-primary group">
        <slot />
    </button>
</template>

<style scoped>
.btn-primary {
    @apply text-white flex justify-center items-center relative h-[54px];
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #1A201E;
    z-index: 1;
    border-radius: 10px;
}

.btn-primary::before {
    @apply top-0 bottom-0 left-0 right-0 absolute opacity-0;
    content: "";
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%), #1A201E;
    z-index: -1;
    transition: opacity 250ms ease-in-out;
    border-radius: 10px;
}
.btn-primary:hover::before {
    opacity: 1;
}
</style>
