<script setup lang="ts">
import moment from 'moment'
import { Event } from '@/interfaces/Event'
import { computed, ComputedRef } from "vue";
import { Tippy } from 'vue-tippy'

const props = defineProps<{
    event: Event,
    selectedEventIds?: string[],
}>();

const isEventSelected: ComputedRef<boolean> = computed(() => {
    return !!props.selectedEventIds?.find(selectedEventId => selectedEventId === props.event.id);
})
</script>

<template>
    <Tippy
        theme="light"
        :content="event.external_link ? event.content : ''"
        :allow-h-t-m-l="true"
    >
        <a
            :href="event.external_link ? event.external_link : event.permalink"
            :target="event.external_link ? '_blank' : '_self'"
            class="block hover:text-secondary-dark transition duration-300"
            :class="{ 'text-secondary-dark': isEventSelected, 'text-black': !isEventSelected }"
        >
            <div class="uppercase text-xs font-semibold text-black flex items-center">
                Événement
                <template v-if="event.domains.length">
                    <span class="mx-2"> / </span>
                    <div class="flex gap-2">
                        <div v-for="domain in event.domains" :key="domain.id" class="flex gap-1 items-center">
                            <div class="w-1.5 h-1.5 rounded-full" :style="'background:' + domain.color" />
                            <div class="uppercase font-semibold text-xs">{{ domain.title }}</div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="event-border pl-4 py-2 my-2 text-2xl">
                <p class="text-lg md:text-xl font-medium mb-2">{{ event.title }}</p>

                <p class="text-lg">
                    <span v-if="event.start_date !== event.end_date">
                        Du {{ moment(event.start_date).format("DD.MM.YYYY") }}
                        au {{ moment(event.end_date).format("DD.MM.YYYY") }}
                    </span>
                    <span v-else>
                        Le {{ moment(event.start_date).format("DD.MM.YYYY") }}
                    </span>
                </p>
            </div>
        </a>
    </Tippy>
</template>

<style scoped>
.event-border{
    border-left: 5px solid rgba(221, 180, 213, 0.60);
}
</style>
