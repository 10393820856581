<script setup lang="ts">
import JobSpecifications from './JobSpecifications.vue'
import { JobSpecification } from '@/interfaces/JobSpecification';
import { Category } from '@/interfaces/Category';
import { Domain } from '@/interfaces/Domain';
import { Media } from '@/interfaces/Media'
import Sharing from '@/components/FicheMetier/Header/Sharing.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { generateSessionCode } from '@/utils'
import Gallery from '@/components/FicheMetier/Header/Gallery.vue'

const props = defineProps<{
    id: string,
    title: string,
    description?: string,
    jobSpecifications: Array<JobSpecification>,
    medias: Array<Media>,
    domains: Array<Domain>,
    categories: Array<Category>,
}>();

const isFavorite = ref(false);

const getFavoriteStatus = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) return false;

    let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

    isFavorite.value = items.includes(props.id.toString());
}

const toggleFavorite = () => {
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId){
        const newSessionId = generateSessionCode();
        localStorage.setItem('sessionId', newSessionId)
        sessionId = newSessionId;
    }

    axios.post('/api/toggle-favorite', {
        jobcard_id: props.id,
        session_id: sessionId
    })
        .then(response => {
            isFavorite.value = response.data.wasAdded

            // Save list of liked fiches metier in local storage
            let items = JSON.parse(localStorage.getItem('session_liked_items') || '[]');

            if (response.data.wasAdded) {
                if (!items.includes(response.data.jocardId)) {
                    items.push(response.data.jocardId);
                }
            } else {
                items = items.filter((item: string) => item !== response.data.jocardId);
            }

            localStorage.setItem('session_liked_items', JSON.stringify(items));
        })
        .catch(error => console.log(error))
}

onMounted(() => {
    getFavoriteStatus()
})
</script>

<template>
    <div>
        <div class="flex lg:items-center flex-col lg:flex-row justify-between gap-4">
            <div class="flex items-center gap-2 lg:flex-row-reverse">
                <h1>{{ title }}</h1>
                <button
                    class="w-11 h-11 bg-white rounded-full flex items-center justify-center shrink-0
                border border-gray-light transition duration-250 like-icon"
                    :class="isFavorite ? 'text-white like-icon-active' : ''"
                    @click="toggleFavorite"
                >
                    <SvgIcon name="heart" class="w-4" />
                </button>
            </div>

            <Sharing title="Partager la fiche metier" />
        </div>

        <div class="flex flex-col md:flex-row gap-1 md:gap-4 md:items-center mt-2.5">
            <div class="flex flex-wrap items-center gap-4">
                <div v-for="domain in domains" :key="domain.id" class="flex gap-2 items-center mt-2">
                    <div class="w-2 h-2 rounded-full" :style="'background:' + domain.color" />
                    <div class="text-xl uppercase font-semibold">
                        {{ domain.title }}
                    </div>
                </div>
            </div>
            <div v-if="categories.length > 0" class="border-t md:h-7 md:border-r border-gray-300 mt-2" />
            <div class="flex flex-wrap mt-2">
                <div v-for="(category, index) in categories" :key="category.id" class="flex gap-2 items-center mr-1.5">
                    <div class="text-xl font-medium">
                        {{ category.title }} {{ index != categories.length - 1 ? '-' : '' }}
                    </div>
                </div>
            </div>
        </div>

        <p class="my-8 text-lg">
            {{ description }}
        </p>

        <JobSpecifications :job-specifications="jobSpecifications" />

        <Gallery v-if="medias.length" :medias="medias" />
    </div>
</template>
