<script setup lang="ts">
import { computed, ref } from 'vue'
import { Tippy } from 'vue-tippy'
import moment from 'moment'
import FileSvg from './FileSvg.vue'
import LinksSection from './LinksSection.vue'
import { Document } from '@/interfaces/Document';
import SvgIcon from '@/components/SvgIcon.vue'
import { Collapse } from 'vue-collapsed'
import { PhMinus, PhPlus } from '@phosphor-icons/vue'

const props = defineProps<{
    documents: Array<Document>,
}>();

const files = computed(() => props.documents.filter(doc => doc.file != null))
const links = computed(() => props.documents.filter(doc => doc.file == null))

const isExpanded = ref(false)
</script>

<template>
    <h2>Documentation</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 mt-7 gap-8">
        <LinksSection v-if="links.length > 0" :links="links" />

        <div v-if="files.length > 0">
            <h3 class="flex text-xl font-semibold uppercase items-center gap-2 mb-4">
                Documents
                <Tippy
                    theme="light"
                    content="Explorez les liens pertinents pour accéder au règlement détaillé des formations post-diplômes
                    spécialisées en soins"
                >
                    <SvgIcon name="info" class="text-gray-400 w-6" />
                </Tippy>
            </h3>

            <div v-for="file in files.slice(0,3)" :key="file.id" class="mb-3">
                <div class="flex items-center gap-3">
                    <FileSvg />
                    <a :href="file.file?.permalink" target="_blank" class="hover:text-primary-light transition duration-150">
                        <span class="block font-medium underline underline-offset-2">{{ file.title }}</span>
                        <span class="block text-sm text-gray-medium">
                            {{ moment(file.file?.last_modified).format("dddd, MMMM Do YYYY") }}
                        </span>
                    </a>
                </div>
            </div>

            <Collapse :when="isExpanded">
                <div v-for="file in files.slice(3,50)" :key="file.id" class="mb-3">
                    <div class="flex items-center gap-3">
                        <FileSvg />
                        <a :href="file.file?.permalink" target="_blank" class="hover:text-primary-light transition duration-150">
                            <span class="block font-medium underline underline-offset-2">{{ file.title }}</span>
                            <span class="block text-sm text-gray-medium">
                                {{ moment(file.file?.last_modified).format("dddd, MMMM Do YYYY") }}
                            </span>
                        </a>
                    </div>
                </div>
            </Collapse>

            <button
                v-if="files.length > 3"
                class="flex items-center gap-2 hover:text-gray-700 text-sm pt-2"
                @click="isExpanded = !isExpanded"
            >
                <span v-if="isExpanded">Afficher moins de documents</span>
                <span v-else>Afficher plus de documents</span>
                <PhMinus v-if="isExpanded" size="16" class="fill-current" />
                <PhPlus v-else size="16" class="fill-current" />
            </button>
        </div>
    </div>
</template>

<style scoped>

</style>
