<script setup lang="ts">
import { News } from '@/interfaces/News';
import NewsInformations from "@/components/News/NewsInformations.vue";
import moment from 'moment'

const props = defineProps<{
    new: News,
    news: News[],
}>();

moment.updateLocale('fr', {
    months: [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
        "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ]
});

</script>

<template>
    <div class="container flex flex-col space-y-12">
        <div class="flex flex-col mb-7">
            <div class="pt-2 pb-6">
                <back-button title="Retour aux actualités" back-url="/actualites" />
            </div>
            <div class="pb-2 space-y-2">
                <h1 class="text-3xl md:text-4xl">
                    {{ props.new.title }}
                </h1>
                <p class="text-gray-medium">
                    Publié le {{ moment(props.new.date).format("DD MMMM YYYY") }}
                </p>
            </div>
            <img :src="props.new.cover_url" alt="" class="w-full md:w-1/3 border-2 border-gray-light rounded-2xl">
            <div class="wysiwyg max-w-5xl" v-html="props.new.content" />
        </div>
        <hr>
        <div class="flex flex-col space-y-8">
            <h3>Autres articles qui pourraient vous intéresser</h3>
            <p v-if="props.news.length <= 0">
                Il n'y a pas d'actualité pour le moment.
            </p>
            <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <NewsInformations v-for="actu in props.news" :key="actu.id" :news="actu" />
            </div>
        </div>
    </div>
</template>
