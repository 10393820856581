<script setup lang="ts">
import moment from 'moment'
import { News } from '@/interfaces/News';
import { Tippy } from 'vue-tippy'

const props = defineProps<{
    news: News,
}>();

moment.updateLocale('fr', {
    months: [
        "janvier", "février", "mars", "aAvril", "mai", "juin", "juillet",
        "août", "septembre", "octobre", "novembre", "décembre"
    ]
});
</script>

<template>
    <Tippy
        theme="light"
        :content="news.external_link ? news.content : ''"
        :allow-h-t-m-l="true"
    >
        <a
            :href="news.external_link ? news.external_link : news.permalink"
            :target="news.external_link ? '_blank' : '_self'"
            class="flex flex-col transform hover:translate-y-[-5px] transition duration-300 border border-gray-light
            rounded-[24px] h-full"
        >
            <img
                class="w-full rounded-t-[24px] max-h-[170px] md:max-h-inherit aspect-[5/3] object-cover"
                :src="news.cover_url || 'https://placehold.co/600x400'" :alt="news.title"
            >
            <div
                class="bg-white rounded-t-[24px] rounded-b-[24px] pt-4 -mt-5 px-4 h-full pb-4 transition duration-250"
            >
                <div class="flex items-center gap-2 mb-2">
                    <span class="uppercase text-xs font-semibold">Actualités</span>
                    <span class="h-1 w-1 bg-gray-400 block rounded-full" />
                    <span class="text-gray-medium">{{ moment(news.date).format("DD MMMM YYYY") }}</span>
                </div>
                <p class="new-border pl-4 md:text-lg font-medium line-clamp-3 hover:text-primary transition duration-300">
                    {{ news.title }}
                </p>
            </div>
        </a>
    </Tippy>
</template>

<style scoped>
.new-border{
    border-left: 5px solid #F1C99B;
}
</style>
