<script setup lang="ts">
import Slider from '@vueform/slider'
import { ref, watch } from 'vue'

const props = defineProps<{
    title: string,
    value: number,
    mode: 'slider' | 'progressbar',
}>();

const emit = defineEmits(['update:value'])

const localValue = ref(props.value);

watch(localValue, (newValue) => {
    emit('update:value', newValue);
});

const widthInPercent = `${(localValue.value / 10) * 100  }%`;
</script>

<template>
    <span class="block pb-2 text-lg">{{ title }}</span>

    <div v-if="mode === 'slider'">
        <Slider v-model="localValue" :min="0" :max="10" show-tooltip="focus" disabled />
    </div>

    <div v-else class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
            class="bg-gradient-to-b from-primary-light to-primary-extra-light h-2.5 rounded-full"
            :style="{ width: widthInPercent }"
        />
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>
