<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import SvgIcon from './SvgIcon.vue'
import { Tab } from '@/interfaces/Tab';

const props = defineProps<{
    tabList: Array<Tab>,
}>();

const activeTab = ref<number | null>(null);
const uid = Math.random().toString(36).substring(2);

const findFirstDisplayedTab = () => {
    const foundTab = props.tabList.findIndex(tab => tab.display !== false);
    return foundTab === -1 ? 1 : foundTab + 1;
};

const activateTab = (index: number) => {
    activeTab.value = index;
}

const hasDisplayableTab = computed(() => {
    return props.tabList.some(tab => tab.display !== false);
});

onMounted(() => {
    activeTab.value = findFirstDisplayedTab();
})
</script>

<template>
    <div v-if="hasDisplayableTab" class="tabs-container">
        <div class="tabs-wrapper">
            <nav class="tabs">
                <template v-for="(tab, index) in tabList" :key="index">
                    <button
                        v-if="tab.display ?? true"
                        :id="`${uid}${index}`"
                        class="tab"
                        :class="[index+1 === activeTab ? 'is-active':'', tab.variant ? 'tab-' + tab.variant : '']"
                        @click="activateTab(index + 1)"
                    >
                        <SvgIcon class="w-6" :name="tab.icon" />
                        {{ tab.title }}
                    </button>
                </template>
            </nav>
        </div>
        <template v-for="(tab, index) in tabList">
            <div v-if="index + 1 === activeTab" :key="index" class="tabs-content">
                <slot :name="`tab-${index + 1}`" />
            </div>
        </template>
    </div>
</template>

<style>
.tabs-container{
    border-radius: 16px;
    border: 1px solid #EDEDED;
    background: #FFF;
}

.tabs-wrapper {
    @apply flex px-6 md:px-8;
    border-bottom: 1px solid #EDEDED;
}

.tabs {
    @apply flex md:justify-center gap-x-8 flex-wrap;
}

.tab {
    @apply font-medium hover:cursor-pointer flex items-center gap-2 py-5 relative overflow-hidden select-none
    text-gray-medium text-left;
}
.tab img{
    filter: invert(0.5);
}

.tab::after {
    @apply block bottom-0 left-0 w-full absolute opacity-0 transition duration-500 bg-primary-light h-[2px];
    content: '';
}

.tab-secondary::after {
    @apply bg-secondary;
}

.tab-tertiary::after {
    @apply bg-tertiary;
}

.tab.is-active::after{
    @apply transform translate-x-0 opacity-100;
}

.tab.is-active{
    @apply text-black;
}

.tab.is-active img{
    filter: invert(0);
}

.tabs-content{
    @apply p-6 px-6 md:px-8 min-h-[100px];
}
</style>
