<script setup lang="ts">
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { News } from '@/interfaces/News';
import { Event } from '@/interfaces/Event';
import EventInformations from '@/components/Event/EventInformations.vue'
import axios from 'axios'
import { Category } from '@/interfaces/Category'
import { Domain } from '@/interfaces/Domain'
import { PhArrowCircleRight } from '@phosphor-icons/vue'

const props = withDefaults(defineProps<{
    news?: Array<News>|null,
    events?: Array<Event>|null,
    eventsTitle?: string,
    newsTitle?: string,
    domains?: Array<Domain>|null,
    categories?: Array<Category>|null,
    dynamicFetch?: boolean,
}>(), {
    news: null,
    events: null,
    domains: null,
    categories: null,
    eventsTitle: 'Événements à venir',
    newsTitle: 'Les dernières actualités',
    dynamicFetch: false
});

const fetchData = () => {
    axios.post('/api/get-news', {
        domains: props.domains?.map(domain => domain.id),
        categories: props.categories?.map(category => category.id)
    })
        .then(response => computedNews.value = response.data.news)
        .catch(error => console.log(error))

    axios.post('/api/get-events', {
        domains: props.domains?.map(domain => domain.id),
        categories: props.categories?.map(category => category.id)
    })
        .then(response => computedEvents.value = response.data.news)
        .catch(error => console.log(error))
}

const computedNews = ref()
const computedEvents = ref()

const sortedNews = computed(() => {
    const newsCopy = [...computedNews.value];
    return newsCopy.sort((a, b) => +moment(b.date) - +moment(a.date)).slice(0, 3);
});

const upcomingEvents = computed(() => {
    const now = moment();
    const eventsCopy = [...(computedEvents.value || [])];
    return eventsCopy
        .filter(event => moment(event.end_date).isSameOrAfter(now))
        .sort((a, b) => moment(a.start_date).diff(moment(b.start_date)))
        .slice(0, 3);
});

onMounted(() => {
    if (props.dynamicFetch){
        fetchData()
    }
    else {
        computedNews.value = props.news;
        computedEvents.value = props.events;
    }
})
</script>

<template>
    <div
        v-if="computedNews?.length > 0 || computedEvents?.length > 0"
        class="space-y-12 lg:space-y-0 lg:flex gap-24 items-start"
    >
        <div v-if="computedNews?.length > 0" class="w-full lg:w-3/5 shrink-0">
            <h3 class="mb-6">
                {{ props.newsTitle }}
            </h3>

            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-6">
                <a
                    v-for="(actu, index) in sortedNews"
                    :key="index"
                    :href="actu.external_link ? actu.external_link : actu.permalink"
                    :target="actu.external_link ? '_blank' : '_self'"
                    class="flex flex-col md:flex-row md:items-center md:gap-6 group border border-gray-light rounded-[32px]
                    overflow-hidden md:w-fit hover:border-primary-extra-light transition duration-300 w-fit"
                    :class="index % 2 === 0 ? 'md:pr-6' : 'md:flex-row-reverse md:pl-6 pr-0'"
                >
                    <img
                        class="w-full h-full max-h-[150px] md:max-w-[230px] md:w-56 object-cover"
                        :src="(actu.cover?.permalink ?? actu.cover_url) || 'https://placehold.co/600x400'" alt=""
                    >
                    <div class="my-5 px-4 md:px-0">
                        <p
                            class="new-border pl-4 mb-2 md:text-lg font-medium group-hover:text-primary-light transition
                            duration-300"
                        >
                            {{ actu.title }}
                        </p>
                        <div class="flex items-center gap-2">
                            <span class="uppercase text-xs font-semibold">Actualités</span>
                            <span class="h-1 w-1 bg-gray-400 block rounded-full" />
                            <span class="text-gray-medium">{{ moment(actu.date).format("DD.MM.YYYY") }}</span>
                        </div>
                    </div>
                </a>
            </div>

            <a
                href="/actualites"
                class="font-semibold flex items-center gap-2 hover:text-primary-light transition duration-150 mt-12"
            >
                Voir plus d'actualités
                <PhArrowCircleRight :size="22" />
            </a>
        </div>

        <div v-if="computedEvents?.length > 0" class="w-full">
            <h3 class="mb-6">
                {{ props.eventsTitle }}
            </h3>

            <div class="flex flex-col gap-4">
                <EventInformations
                    v-for="event in upcomingEvents"
                    :key="event.id"
                    :event="event"
                />
            </div>

            <div v-if="upcomingEvents.length === 0">
                Aucun événement à venir
            </div>
            <a
                href="/evenements"
                class="font-semibold flex items-center gap-2 hover:text-secondary-dark transition duration-150 mt-10"
            >
                Voir plus d’événements
                <PhArrowCircleRight :size="22" />
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.new-border{
    border-left: 5px solid #F1C99B;
}
</style>
